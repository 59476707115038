import { getToken, setToken, removeToken } from "@/utils/token";
import { login, getMyInfo, logout } from "@/api/user.js";
import { resetRouter } from "@/router/router.js";

export default {
	namespaced: true,
	state: {
		token: getToken(),
		name: "",
		userInfo: "",
		avatar: "",
		mobile: "",
		roles: [],
	},
	mutations: {
		setToken: (state, token) => {
			state.token = token;
		},
		setInfo: (state, userInfo) => {
			state.userInfo = userInfo;
		},
		setMobile: (state, mobile) => {
			state.mobile = mobile;
		},
		setName: (state, name) => {
			state.name = name;
		},
		setAvatar: (state, avatar) => {
			state.avatar = avatar;
		},
		setRoles: (state, roles) => {
			state.roles = roles;
		},
	},
	actions: {
		// 登录
		login({ commit }, form) {
			return new Promise((resolve, reject) => {
				login(form).then(res => {
					commit("setToken", res.data.userinfo.token);
					setToken(res.data.userinfo.token);
					resolve(res);
				});
			});
		},
		// 获取用户信息
		getInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				getMyInfo(state.token).then(res => {
					const { section, username, mobile, avatar } = res.data;
					commit("setInfo", res.data);
					commit("setRoles", section);
					commit("setName", username);
					commit("setAvatar", avatar);
					commit("setMobile", mobile);
					resolve(res);
				});
			});
		},
		// 退出登录
		logout({ commit }) {
			return new Promise((resolve, reject) => {
				logout().then(() => {
					commit("setToken", "");
					commit("setInfo", {});
					commit("setRoles", []);
					commit("setName", "");
					commit("setAvatar", "");
					commit("setMobile", "");
					removeToken();
					resetRouter();
					resolve();
				});
			});
		},
		// 重置 token
		resetToken({ commit }) {
			return new Promise(resolve => {
				commit("setToken", "");
				commit("setInfo", {});
				commit("setRoles", []);
				removeToken();
				resolve();
			});
		},
	},
};

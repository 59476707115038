import { asyncRoutes } from "@/router/router.js";
import sidebar from "@/router/modules/nav.js";
export default {
	namespaced: true,
	state: {
		addRoutes: [], // 要添加的异步路由
		allRoutes: [], // 根据权限合并后的路由
		sidebarMenu: [],
	},
	mutations: {
		setSidebarActive(state, arr) {
			state.sidebarActive = arr;
		},
		addRoutes(state, routes) {
			state.addRoutes = routes;
			state.allRoutes = sidebar.concat(routes);
			state.sidebarMenu = generateSidebarMenu(state.allRoutes);
		},
		changeRoute(state, space) {
			setRouteActive(space, state.sidebarMenu);
		},
	},
	actions: {
		/**
		 * 生成侧边所需的 routes
		 * @param {*} state
		 * @param {*} roles: 用户的所有权限
		 */
		generateRoutes: ({ commit }, roles) => {
			return new Promise(resolve => {
				const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
				commit("addRoutes", accessedRoutes);
				resolve(accessedRoutes);
			});
		},
	},
};
// 修改路由的激活状态
function setRouteActive(space, menu) {
	const s = space.join("-").split("-");
	const i = s.shift();
	menu.forEach((item, index) => {
		item.active = false;
		if (i == index) {
			item.active = true;
		}
		if (item.children) {
			setRouteActive(s, item.children);
		}
		// if (item.children) {
		// 	if (i == index) {
		// 		setRouteActive(space, item.children, true);
		// 	} else {
		// 		setRouteActive(space, item.children);
		// 	}
		// }
	});
}
/**
 * 使用 meta.role 判断当前用户是否有权限
 * @param roles: 用户的所有权限
 * @param route: 路由
 */
function hasPermission(roles, route) {
	if (route.meta && route.meta.roles) {
		return route.meta.roles == roles;
	} else {
		return true;
	}
}

/**
 * 通过递归过滤异步路由表
 * @param routes: 异步路由
 * @param roles: 用户的所有权限
 */
function filterAsyncRoutes(routes, roles) {
	const res = [];
	routes.forEach(route => {
		if (hasPermission(roles, route)) {
			if (route.children) {
				route.children = filterAsyncRoutes(route.children, roles);
			}
			res.push(route);
		}
	});
	return res;
}

/**
 * 生成 sidebar 所需的数据格式简化路由
 * @param {*} routes: 路由元数据
 */
function generateSidebarMenu(routes, group = [], fatherSpace = []) {
	routes.forEach((route, index) => {
		const space = [...fatherSpace, index];
		if (!route.hidden) {
			if (!route.children) {
				const meta = route.meta || "";
				group.push({
					path: route.path,
					name: route.name,
					space,
					active: false,
					icon: meta.icon || "",
					title: meta.title || route.name || "",
					meta,
				});
			} else if (route?.children.length === 1) {
				let meta,
					name,
					children = [];

				if (route.need) {
					// 当只有一个子路由时也需要父级显示则使用父级的 meta
					meta = route.meta || "";
					name = route.name || "";
				} else {
					meta = route.children[0].meta || "";
					name = route.children[0].name || "";
				}
				const o = {
					path: route.path,
					name,
					space,
					active: false,
					icon: meta.icon,
					title: meta.title || route.name || "",
					meta,
				};
				// 判断子路由是否还有子集有的话继续递归
				if (route.need) {
					o.children = children;
					generateSidebarMenu(route.children, children, space);
				}
				group.push(o);
			} else if (route?.children.length > 1) {
				const meta = route.meta || "";
				const children = [];
				group.push({
					path: route.path,
					name: route.name,
					space,
					active: false,
					icon: meta.icon,
					title: meta.title || route.name || "",
					children,
					meta,
				});
				generateSidebarMenu(route.children, children, space);
			}
		}
	});
	return group;
}

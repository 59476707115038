import request from './request'
/**
 * @description: 获取部门
 * @param {*} isAll: 是否携带全部选择
 * @return {*}
 */
export function getSector(isAll) {
  return request({
    url: '/admin/groupInfo',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then(res => {
    res.data.forEach(e => {
      e.isChecked = false
    })
    if (isAll) {
      res.data.unshift({ id: 0, isChecked: true, name: '全部' })
    } else {
      res.data[0].isChecked = true
    }
    return res.data
  })
}
export function upload(data) {
  return request.post('/common/upload', data, {})
}
// export function upload(formData, data) {
// 	return request.post("/common/upload", formData, {
// 		headers: { "Content-Type": "multipart/form-data", enctype: "multipart/form-data" },
// 		data,
// 	});
// }

// 客户公共列表参数
export function getTypeList(type) {
  return request({
    url: '/order.order/typelist',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: {
      type
    }
  }).then(res => {
    res.data.forEach((v, i) => {
      v.isChecked = false
    })
    if (type != 11) {
      res.data.unshift({ id: '', isChecked: true, name: '全部' })
    }
    return res.data
  })
}

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  // mounted() {
  //   if (!this.$route.name) {
  //     this.$router.replace({ name: 'Home' })
  //   }
  // }
};
</script>

<style lang="scss">
@import url("./styles/reset.css");
@import url("./styles/public.css");
@import url("./styles/normalize.css");

.container {
  font-family: Source Han Sans CN;
  user-select: none;
}

.view-page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
}
</style>

<template>
  <div class="paging" v-if="total>pageSize">
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :hide-on-single-page="isShow" :background="true" :page-size="pageSize" layout="prev, pager, next" :total="total">
    </el-pagination>

    <template>
      <div class="totalPage">共{{ totalPage }}页</div>
      <div class="jump">
        <span>跳到</span>
        <input type="text" v-model.number="value" />
        <span>页</span>
      </div>
      <div class="btn" @click="skip">确定</div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1, //当前页数
      value: 1,
      isShow: true,
    };
  },
  props: {
    /**
     * @description: 一共多少条
     */
    total: {
      type: [Number, String],
      required: true,
    },
    /**
     * @description: 每一页多少条
     */
    pageSize: {
      type: Number,
      default: 20
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  methods: {
    /**
     * 上、下一页
     */
    handleCurrentChange() {
      this.value = this.currentPage;
      this.$emit('change', this.currentPage)
    },
    /**
     * 跳转页数
     */
    /**
     * @description: 
     * @@ param: 
     * @return {*}
     */
    skip() {
      this.currentPage = this.value;
      this.$emit('change', this.currentPage)
    },
  },
};
</script>
<style>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  color: #595959;
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 0.73rem;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0.31rem;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #179d92;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: linear-gradient(37deg, #1db8ab, #179d92);
  color: #fff;
}
</style>
<style lang="scss" scoped>
.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;

  .btn {
    width: 2.8rem;
    height: 1.98rem;
    margin-left: 10px;
    text-align: center;
    line-height: 1.98rem;
    font-size: 0.73rem;
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    border-radius: 0.31rem;
    background: linear-gradient(215deg, #1db8ab, #179d92);
  }

  .jump {
    color: #595959;
    font-size: 0.73rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      width: 1.98rem;
      height: 1.98rem;
      border-radius: 0.31rem;
      border: 1px solid #dfdfdf;
      text-align: center;
      margin: 0 10px;
    }
  }

  .totalPage {
    color: #595959;
    font-size: 0.73rem;
    font-weight: 400;
    margin-right: 10px;
  }
}
</style>
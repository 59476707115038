export default function iconUrl(icon, active) {
  var iconObj = {
    // 路由 icon
    checking: require('@/assets/icon/checking.png'),
    'checking-active': require('@/assets/icon/checking-active.png'),
    approval: require('@/assets/icon/approval.png'),
    'approval-active': require('@/assets/icon/approval-active.png'),
    case: require('@/assets/icon/case.png'),
    'case-active': require('@/assets/icon/case-active.png'),
    client: require('@/assets/icon/client.png'),
    'client-active': require('@/assets/icon/client-active.png'),
    contacts: require('@/assets/icon/contacts.png'),
    'contacts-active': require('@/assets/icon/contacts-active.png'),
    daily: require('@/assets/icon/daily.png'),
    'daily-active': require('@/assets/icon/daily-active.png'),
    system: require('@/assets/icon/system.png'),
    'system-active': require('@/assets/icon/system-active.png'),
    memo: require('@/assets/icon/memo.png'),
    'memo-active': require('@/assets/icon/memo-active.png'),
    home: require('@/assets/icon/home.png'),
    'home-active': require('@/assets/icon/home-active.png'),
    sector: require('@/assets/icon/sector.png'),
    'sector-active': require('@/assets/icon/sector-active.png'),
    // icon
    logo: require('@/assets/icon/logo.png'),
    legalAffairs: require('@/assets/icon/legalAffairs.png'),
    drop: require('@/assets/icon/drop.png'),
    hr: require('@/assets/icon/hr.png'),
    leader: require('@/assets/icon/leader.png'),
    announcement: require('@/assets/icon/announcement.png'),
    reception: require('@/assets/icon/reception.png'),
    information: require('@/assets/icon/information.png'),
    down: require('@/assets/icon/down.png'),
    password: require('@/assets/icon/password.png'),
    'personal-data': require('@/assets/icon/personal-data.png'),
    quit: require('@/assets/icon/quit.png'),
    close: require('@/assets/icon/close.png'),
    search: require('@/assets/icon/search.png'),
    zuo: require('@/assets/icon/zuo.png'),
    you: require('@/assets/icon/you.png'),
    vital: require('@/assets/images/case/vital.png'),
    edit: require('@/assets/images/home/edit.png'),
    data: require('@/assets/images/rests/data.png'),
    up_img: require('@/assets/images/rests/up_img.png'),
    // 上传文件类型
    pdf: require('@/assets/icon/pdf.png'),
    excel: require('@/assets/icon/excel.png'),
    word: require('@/assets/icon/word.png')
  }
  if (active) {
    return iconObj[`${icon}-active`]
  }
  return iconObj[icon]
}

import Vue from "vue";
import router from "@/router/router";

export default {
	namespaced: true,
	state: {
		activeTag: "Home",
		tags: {
			Home: {
				title: "首页",
				space: [0],
				name: "Home",
			},
		},
	},
	mutations: {
		/**
		 * 添加 tag
		 * @param {*} key: tag 的 key
		 * @param {*} value: tag 的 value
		 */
		addTag(state, { key, value }) {
			if (!(key in state.tags)) {
				Vue.set(state.tags, key, value);
			}
			state.activeTag = key;
		},
		/**
		 * 关闭 tag
		 * @param {*} tag: tag 的 key
		 */
		closeTag(state, tag) {
			const keys = Object.keys(state.tags);
			if (tag === state.activeTag) {
				const i = keys.indexOf(tag);
				if (i < keys.length - 1) {
					state.activeTag = keys[i + 1];
				} else {
					state.activeTag = keys[i - 1];
				}
			}
			Vue.delete(state.tags, tag);
			// router.replace({ name: this.activeTag });
			// this.$router
		},
	},
	actions: {},
};

export default function deepClone(obj) {
	var _toString = Object.prototype.toString;

	if (!obj || typeof obj !== "object") {
		return obj;
	}

	if (obj.nodeType && "cloneNode" in obj) {
		return obj.cloneNode(true);
	}

	if (_toString.call(obj) === "[object Date]") {
		return new Date(obj.getTime());
	}
	if (_toString.call(obj) === "[object RegExp]") {
		var flags = [];
		if (obj.global) {
			flags.push("g");
		}
		if (obj.multiline) {
			flags.push("m");
		}
		if (obj.ignoreCase) {
			flags.push("i");
		}
		return new RegExp(obj.source, flags.join(""));
	}

	var result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {};

	for (var key in obj) {
		result[key] = deepClone(obj[key]);
	}
	return result;
}

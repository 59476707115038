<template>
  <div class="lawyer-page">
    <operating>
      <router-view />
    </operating>
  </div>
</template>

<script>
import operating from '@/views/lawyer/Operating.vue'
export default {
  components:{
    operating
  },
  name:'Lawyer',
}
</script>


<template>
  <div class="upload-view">
    <div class="fils-list" v-for="(item, index) in files" :key="index">
      <i class="list-icon el-icon-error" @click="close(index)"></i>
      <img class="list-img" v-if="item.type === 'img'" :src="item.url" alt="图片" />
      <img class="list-file" v-else :src="iconUrl(item.type)" alt="图片" />
    </div>
    <div class="upload-container" v-show="files.length < limit">
      <div class="upload-content">
        <img :src="iconUrl('up_img')" alt="" />
        <!-- <i class="icon" :class="icon"></i>
        <p>上传文件</p> -->
      </div>
      <input id="upload" type="file" />
    </div>
  </div>
</template>

<script>
import { upload } from '@/api/common.js'
import iconUrl from '@/utils/icon.js'
export default {
  props: {
    icon: {
      type: String,
      default: 'el-icon-upload'
    },
    limit: {
      type: Number,
      default: 9
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'headimg'
    },
    isDocument: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconUrl: iconUrl,
      files: []
    }
  },
  mounted() {
    const self = this
    const input = document.querySelector('#upload')
    if (this.multiple) {
      input.setAttribute('multiple', 'multiple')
    }
    let fileType = ''
    if (this.isDocument) {
      fileType = /(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG|pdf|PDF|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|msword|msexcel)/
    } else {
      fileType = /(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)/
    }
    input.onchange = function () {
      const file = input.files
      for (const item of file) {
        const type = item.type.split('/')[1]
        if (!fileType.test(type)) {
          self.$message({
            message: `此文件 ${item.name} 不支持上传，请更换格式。`,
            type: 'warning'
          })
          return
        }
      }
      if (file.length + self.files.length > self.limit) {
        self.$message({
          message: `最多上传 ${self.limit} 项`,
          type: 'warning'
        })
        return
      }
      const concurrent = []
      let _file = [];
      for (const item of file) {
        const formData = new FormData()
        formData.append('file', item)
        formData.append('type', self.type)
        _file.push(item)
        const up = upload(formData)
        concurrent.push(up)
      }
      input.setAttribute('type', 'text')
      Promise.all(concurrent)
        .then(res => {
          res.forEach((e, i) => {
            self.files.push({
              file: _file[i],
              url: e.data.fullurl,
              type: self.fileType(e.data.fullurl)
            })
            input.setAttribute('type', 'file')
          })
          self.$emit('on-success', self.files)
        })
        .catch(err => {
          self.$emit('on-error')
        })
    }
  },
  methods: {
    fileType(url) {
      const type = url.split('.').pop()
      const imgType = /(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)/
      const pdfType = /(pdf|PDF)/
      const wordType = /(doc|DOC|docx|DOCX)/
      const excelType = /(xls|XLS|xlsx|XLSX)/
      if (imgType.test(type)) {
        return 'img'
      } else if (pdfType.test(type)) {
        return 'pdf'
      } else if (wordType.test(type)) {
        return 'word'
      } else if (excelType.test(type)) {
        return 'excel'
      }
    },
    close(index) {
      this.files.splice(index, 1)
      this.$emit('on-remove', this.files)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.fils-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.27rem;
  height: 5.36rem;
  border-radius: 10px;
  margin: 10px;

  .list-icon {
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: -7px;
    font-size: 0.55rem;
    color: #999;
  }

  .list-img {
    width: 5.27rem;
    height: 5.36rem;
    border-radius: 10px;
  }

  .list-file {
    width: 60px;
    height: 60px;
  }
}

.upload-container {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin: 10px;
  width: 5.27rem;
  height: 5.36rem;
  border: 1px dashed #ccc;
  border-radius: 10px;
  background-color: #f6f5f8;

  .upload-content {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5.27rem;
    height: 5.36rem;
    // .icon {
    //   font-size: 30px;
    //   color: #999;
    // }
    img {
      width: 5.27rem;
      height: 5.36rem;
    }

    > p {
      padding-top: 10px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 1;
    }
  }

  #upload {
    opacity: 0;
    z-index: 2;
    position: absolute;
    width: 5.27rem;
    height: 5.36rem;
  }
}
</style>

import router from "./router/router";
import store from "./store/store";
import { resetRouter } from "@/router/router.js";
import { getToken, removeToken } from "@/utils/token"; // 从 cookie 中获取令牌

const whiteList = ["/login", "/register"]; // 白名单
router.beforeEach(async (to, from, next) => {
	// 如果用户要去一个空的路由，直接重定向到主页
	if (to.path == "/") {
		next({ name: "Home" });
	}
	const hasToken = getToken();
	// 判断是否有 token，如果设置过 token 则代表登录成功
	if (hasToken != null && hasToken != "undefined") {
		// 如果已经登录，用户再次去首页，则重定向到主页
		if (whiteList.indexOf(to.path) !== -1) {
			next({ name: "Home" });
		} else {
			const hasRoles = store.getters.roles && store.getters.roles.length > 0;
			// 判断用户是否通过 getInfo 获取了自己的权限角色
			if (hasRoles) {
				if (!from.name && to.name !== "Home") {
					next({ name: "Home", replace: true });
				} else {
					next();
				}
			} else {
				// 如果没有获取过用户信息则获取一下
				const info = await store.dispatch("user/getInfo");
				// 获取到用户权限
				const sectionrel = info.data.sectionrel;
				let roles = "";
				for (const key in sectionrel) {
					if (sectionrel[key]) {
						roles = key;
					}
				}
				// 根据角色生成可访问的路线图
				const accessRoutes = await store.dispatch("sidebar/generateRoutes", roles);
				resetRouter();
				// 动态添加可访问路由
				for (let i = 0, length = accessRoutes.length; i < length; i += 1) {
					const element = accessRoutes[i];
					router.addRoute(element);
				}

				// hack 方法来确保 addRoutes 是完整的
				// 设置 replace：true 所以导航不会留下历史记录
				next({ ...to, replace: true });
			}
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			next(); // 在白名单中，直接 next
		} else {
			next({ name: "Login" }); // 其他无权访问的页面被重定向到登录页面
		}
	}
});

// router.afterEach((to, from) => {
// 	// 如果不想要路由被添加到 nav 栏, 配置到这里
// 	const exclude = ["Login"];
// 	if (!exclude.includes(to.name)) {
// 		// 路由跳转时添加 tag
// 		store.commit("tags/addTag", {
// 			key: to.name,
// 			value: to.meta.title || to.name,
// 		});
// 	}
// });

<template>
  <div class="container">
    <!-- 中间背景 -->
    <div class="login-right">
      <!-- 右边表单盒子 -->
      <div class="login-from">
        <!-- 欢迎语 -->
        <div class="welcome-text">
          <p class="title-top">Hello,欢迎注册杰律案管</p>
          <p class="title-bottom">WELCOME HERE</p>
        </div>
        <!-- 表单 -->
        <div class="from">
          <el-form ref="form" :rules="rules" :model="form">
            <el-form-item prop="nam+e">
              <el-input class="inp" type="text" v-model="form.username" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item prop="mobile">
              <el-input class="inp" type="text" v-model="form.mobile" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="请输入短信验证码" v-model="form.noteCode">
                <el-button slot="append" @click="getCode">{{show?'获取验证码':count}}</el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入6-18位登录密码"></el-input>
            </el-form-item>
            <el-form-item prop="password_confirm">
              <el-input type="password" v-model="form.password_confirm" autocomplete="off" placeholder="请再次输入6-8位登录密码"></el-input>
            </el-form-item>
          </el-form>
          <div class="text">
            <router-link class="goToRegister" to="login"> 已有账号<span>，去登录</span> </router-link>
          </div>
        </div>

        <!-- 注册按钮   用户协议 -->
        <div class="btn">
          <button @click="registerAccount">注册</button>
          <router-link class="agreement" to="">
            <span>登录即表示阅读和同意《用户协议》</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as userApi from '@/api/user.js'
export default {
  name: 'register',
  data() {
    let verifyMobile = (rule, value, callback) => {
      let reg = /^1[3-9]\d{9}$/
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        this.isGetYzm = true
        callback();
      }
    };
    let verifyPassword1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error('密码长度为6-18位'))
      } else {
        callback();
      }
    };
    let verifyPassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        mobile: [
          { validator: verifyMobile, trigger: 'blur' },
        ],
        password: [
          { validator: verifyPassword1, trigger: 'blur' }
        ],
        password_confirm: [
          { validator: verifyPassword2, trigger: 'blur' }
        ],
      },
      form: {
        username: '', //真实姓名
        mobile: '', //手机号
        password: '', //密码
        // noteCode: '', //验证码
        password_confirm: '' //再次输入密码
      },
      isGetYzm: false,
      time: null,
      count: '',
      show: true
    }
  },
  created() {
    this.agreement()
  },
  methods: {
    /**
     * 验证码接口
     */
    send() {
      const param = {
        mobile: this.form.mobile,
        event: 'mobile'
      }
      userApi.send(param).then(res => {
        if (res.code == 1) {
          console.log('res :>> ', res)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 获取验证码
     */
    getCode() {
      if (this.isGetYzm) {
        const time_count = 30
        if (!this.time) {
          this.count = time_count
          this.show = false
          this.time = setInterval(() => {
            if (this.count > 0 && this.count <= time_count) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.time)
              this.time = null
            }
          }, 1000)
          this.send()
        }
      }
    },
    /**
     * 注册
     */
    registerAccount() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          userApi.register(this.form).then(res => {
            if (res.code == 1) {
              this.$router.push({ name: 'Home' })
            }
          })
        } else {
          this._showMsg('请正确填写相关信息')
          return false;
        }
      });
    },
    /**
     * 隐私协议
     */
    agreement() {
      userApi.agreement()
    }
  }
}
</script>
<style>
.el-button--default {
  width: 120px !important;
}
</style>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-size: 100% 100vh;
  background-image: url("~@/assets/images/login/bg-1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  .login-right {
    width: 80%;
    height: 85%;
    background-size: 100% 100%;
    background-image: url("~@/assets/images/login/bg-2.png");
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .login-from {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2%;
      .welcome-text {
        margin-bottom: 7%;
        text-align: center;
        .title-top {
          font-size: 31px;
          font-weight: bold;
          margin-bottom: 6%;
          background-image: -webkit-linear-gradient(50deg, #1db8ab, #179d92);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .title-bottom {
          font-size: 15px;
          font-weight: 400;
          color: #b4b4b4;
        }
      }

      .from {
        margin-bottom: 2%;
        .inp {
          font-size: 13px;
          font-weight: 400;
          height: 3rem;
          background: #f6f7fb;
          border-radius: 32px;
          outline: none;
          border: none;
        }
        // .inp {
        //   display: grid;
        //   grid-template-columns: 316px;
        //   row-gap: 26px;
        //   .inp::placeholder {
        //     color: #b4b4b4;
        //   }
        //   .noteCode {
        //     display: grid;
        //     grid-template-columns: auto 98px;
        //     .inp {
        //       border-radius: 32px 0px 0px 32px;
        //     }
        //     span {
        //       color: #b4b4b4;
        //       background: #f6f7fb;
        //       height: 3rem;
        //       display: inline-block;
        //       text-align: center;
        //       padding: 17px 0;
        //       font-size: 12px;
        //       box-sizing: border-box;
        //       border-radius: 0px 32px 32px 0;
        //     }
        //   }
        // }

        .text {
          padding: 20px 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .goToRegister {
            font-size: 12px;
            color: #666666;
            span {
              cursor: pointer;
              color: #179f94;
            }
          }
        }
      }

      .btn {
        button {
          cursor: pointer;

          letter-spacing: 10px;
          font-size: 14px;
          color: #ffffff;
          width: 316px;
          height: 46px;
          background: linear-gradient(50deg, #1db8ab, #179d92);
          box-shadow: 0px 2px 8px 0px rgba(11, 121, 112, 0.5);
          border-radius: 32px;
        }

        .agreement {
          padding: 20px 11px;
          display: grid;
          justify-content: start;
          align-items: center;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

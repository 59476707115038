import request from "./request";

export function login(data) {
	return request({
		url: "/login/appLogin",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

export function getMyInfo() {
	return request({
		url: "/admin/myinfo",
		headers: { "Content-Type": "multipart/form-data" },
		method: "get",
	});
}

export function logout() {
	return request({
		url: "/admin/loginOut",
		method: "get",
	});
}
// 注册
export function register(data) {
	return request({
		url: "/login/appRegister",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
// 隐私协议
export function agreement() {
	return request({
		url: "/admin/agreement",
		method: "get",
	});
}

// 获取验证码
export function send(data) {
	return request({
		url: "/sms/send",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

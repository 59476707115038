import request from './request'

export function getCase(data) {
  return request({
    url: '/order.order/pubList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
export function getAnnouncement(data) {
  return request({
    url: '/notice/list',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

export function getStatistics() {
  return request({
    url: '/index/pubTotalNum',
    method: 'get',
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 *
 * @returns 修改密码
 */
export function setAppSavePwd() {
  return request({
    url: '/admin/savePassword',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

<template>
  <div class="c-operate">
    <slot name="left"><i></i></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.c-operate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.65rem 2rem;
  .btn-group {
    display: flex;
    align-items: center;
  }
  .btn-list {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.77rem;
    padding: 0.57rem 1.56rem;
    border-radius: 0.31rem;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 1;
  }
}
</style>

<template>
  <div class="container">
    <!-- 中间背景 -->
    <div class="login-right">
      <!-- 右边表单盒子 -->
      <div class="login-from">
        <!-- 欢迎语 -->
        <div class="welcome-text">
          <p class="title-top">Hello,欢迎登录杰律案管</p>
          <p class="title-bottom">WELCOME HERE</p>
        </div>
        <!-- 表单 -->
        <div class="from">
          <div class="inp">
            <input class="no-arrow" type="number" v-model="form.account" placeholder="请输入账号" />
            <input type="password" v-model="form.password" placeholder="请输入密码" />
          </div>
          <div class="text">
            <div class="rememb">
              <input v-model="chec" type="checkbox" id="lock" />

              <label for="lock"> 记住密码 </label>
            </div>
            <router-link class="goToRegister" to="register"> 没有账号<span>，去注册</span> </router-link>
          </div>
        </div>
        <!-- 登录按钮 -->
        <div class="btn" @click="loginAccount">
          <button>登录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      form: {
        // account: '17589989889', // 默认
        // account: '17600738993', // 法务部
        // account: '17589989889', // 人事部
        account: '', // 人事部
        password: ''
      },
      chec: false
    }
  },
  methods: {
    loginAccount() {
      if (!this.form.account && !this.form.password) return this.$message.error('请输入账号或密码')
      /**
       * 这里验证密码的正确性，如果密码正确的话就请求登录
       */
      this.$store.dispatch('user/login', this.form).then(res => {
        // 登录成功后跳转到首页
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-size: 100% 100vh;
  background-image: url('~@/assets/images/login/bg-1.png');
  display: flex;
  justify-content: center;
  align-items: center;
  .login-right {
    width: 80%;
    height: 85%;
    background-size: 100% 100%;
    background-image: url('~@/assets/images/login/bg-2.png');
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .login-from {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .welcome-text {
        margin-bottom: 11%;
        text-align: center;
        .title-top {
          font-size: 31px;
          font-weight: bold;
          margin-bottom: 6%;
          background-image: -webkit-linear-gradient(50deg, #1db8ab, #179d92);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .title-bottom {
          font-size: 15px;
          font-weight: 400;
          color: #b4b4b4;
        }
      }

      .from {
        margin-bottom: 9%;
        .inp {
          display: grid;
          grid-template-columns: 316px;
          row-gap: 26px;
          input {
            font-size: 13px;
            font-weight: 400;
            height: 46px;
            background: #f6f7fb;
            border-radius: 32px;
            outline: none;
            border: none;
            padding: 17px 22px;
          }
          input::placeholder {
            color: #b4b4b4;
          }
        }

        .text {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .rememb {
            input[type='checkbox'] {
              width: 12px;
              height: 12px;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              line-height: 12px;
              cursor: pointer;
              position: relative;
            }

            input[type='checkbox']::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              background: #fff; /* 未选中时的背景颜色 */
              width: 100%;
              height: 100%;
              border: 1px solid #179f94; /* 未选中时的边框颜色 */
            }

            input[type='checkbox']:checked::before {
              content: '\2713'; /* 对号的转义符 √ */
              background-color: #fff; /* 选中时的背景颜色 */
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              border: 1px solid #179f94; /* 选中后的边框颜色 */
              color: #179f94; /* 选中后的文字颜色   */
              font-size: 12px;
              font-weight: bold;
            }
            label {
              cursor: pointer;
              font-size: 12px;
              color: #179f94;
              padding: 0 5px;
            }
          }

          .goToRegister {
            font-size: 12px;
            color: #666666;
            span {
              cursor: pointer;
              color: #179f94;
            }
          }
        }
      }

      .btn {
        button {
          cursor: pointer;

          letter-spacing: 10px;
          font-size: 14px;
          color: #ffffff;
          width: 316px;
          height: 46px;
          background: linear-gradient(50deg, #1db8ab, #179d92);
          box-shadow: 0px 2px 8px 0px rgba(11, 121, 112, 0.5);
          border-radius: 32px;
        }
      }
    }
  }
  .no-arrow::-webkit-outer-spin-button,
  .no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>

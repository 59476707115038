import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user.js";
import sidebar from "./modules/sidebar.js";
import tags from "./modules/tags.js";
import getters from "./getters.js";
import * as commonApi from "@/api/common";
Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		/**
		 * 部门数据
		 */
		departmentList: [],
		departmentObj: {},
		departmentListAll: [],
	},
	mutations: {
		/**
		 * @description: 设置部门选择列表 ( 不包含全部 )
		 * @param {Array} list: 列表
		 */
		setDepartmentList(state, list) {
			list.forEach(i => {
				state.departmentObj[i.id] = i.name;
			});
			state.departmentList = list;
		},
		/**
		 * @description: 设置部门选择列表 ( 包含全部 )
		 * @param {Array} listAll: 列表
		 */
		setDepartmentListAll(state, listAll) {
			state.departmentListAll = [...listAll];
		},
	},
	actions: {
		/**
		 * @description: 获取部门选项列表
		 */
		async getDepartmentList({ commit }) {
			const list = await commonApi.getSector();
			commit("setDepartmentList", list);
			const listAll = await commonApi.getSector(true);
			commit("setDepartmentListAll", listAll);
		},
	},
	getters,
	modules: {
		user,
		sidebar,
		tags,
	},
});

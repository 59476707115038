import CTable from "./c-table.vue";
import CSearch from "./c-search.vue";
import CNavigation from "./c-navigation.vue";
import COperate from "./c-operate.vue";
import CPagination from "./c-pagination.vue";
import CUpload from "./c-upload.vue";
import CDialog from "./c-dialog.vue";
import CDatePicker from "./c-date-picker.vue";

export default function install(Vue) {
	Vue.component("c-table", CTable);
	Vue.component("c-search", CSearch);
	Vue.component("c-navigation", CNavigation);
	Vue.component("c-operate", COperate);
	Vue.component("c-pagination", CPagination);
	Vue.component("c-upload", CUpload);
	Vue.component("c-dialog", CDialog);
	Vue.component("c-date-picker", CDatePicker);
}

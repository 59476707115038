<template>
  <div class="dialog-container" v-if="value" @click.stop="" :style="{background:mask?'rgba(0, 0, 0, 0.5)':'rgba(0, 0, 0, 0)'}">
    <div class="content">
      <div class="top">
        <p class="title">{{title}}</p>
        <i v-if="close" class="icon el-icon-close" @click.stop="closure"></i>
      </div>
      <slot></slot>
      <div class="btn-group">
        <div class="cancel" v-if="cancel" @click.stop="closure">取消</div>
        <div class="save" v-if="save" @click.stop="confirm">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    close: {
      type: Boolean,
      default: true,
    },
    cancel: {
      type: Boolean,
      default: true
    },
    save: {
      type: Boolean,
      default: true
    },
    mask: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    closure() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  .content {
    z-index: 1000;
    border-radius: 1.04rem;
    padding: 0 1.5rem 2rem;
    background-color: #fff;
    box-shadow: 0px 0px 10px #aeaeae;
  }
}
.top {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  width: 100%;
  .title {
    font-size: 1.04rem;
    font-family: Source Han Sans CN;
    font-weight: 600;
    color: #000000;
    line-height: 1.04rem;
  }
  .icon {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  .cancel,
  .save {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
    width: 5.56rem;
    height: 1.91rem;
    background: #e5ebeb;
    border-radius: 0.78rem;
    font-size: 0.83rem;
    line-height: 1;
    font-weight: 400;
  }
  .cancel {
    background: #e5ebeb;
    color: #333;
  }
  .save {
    background: linear-gradient(215deg, #1db8ab, #179d92);
    color: #ffffff;
  }
}
</style>
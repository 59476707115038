import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./permission"; // 权限控制

import time from "./utils/time.js";
Vue.prototype._time = time;

// 引入全局 icon 路径
import iconUrl from "@/utils/icon.js";
Vue.prototype.$icon = iconUrl;

Vue.use(ElementUI);

// 引入全局组件
import globalComponents from "./components/global.js";
Vue.use(globalComponents);

Vue.config.productionTip = false;

function setFontSize() {
	let rem = window.innerWidth / 100;
	if (rem < 13.66) {
		document.documentElement.style.fontSize = "13.66px";
	} else {
		document.documentElement.style.fontSize = rem + "px";
	}
}

setFontSize();
window.addEventListener("resize", () => {
	setFontSize();
});

const vm = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");

Vue.prototype._showMsg = (message, type = "warning") => {
	vm.$message({
		message,
		type,
	});
};

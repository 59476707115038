import axios from 'axios'
import { getToken } from '@/utils/token' // 从 cookie 中获取令牌
import Vue from 'vue'
// import router from "vue-router";
import router from '@/router/router'
import { removeToken } from '@/utils/token' // 从 cookie 中获取令牌
import config from '@/utils/config.js'
console.log('[ 🎯 config.baseurl + "/" ]', config.baseUrl + '/')
const service = axios.create({
  baseURL: config.baseUrl + '/', // url = base url + request url

  // baseURL: 'http://pc.jielvchina.com:8089/api/', // url = base url + request url
  // withCredentials: true, // 跨域请求时发送 cookies
  timeout: 5000 // 请求超时
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      // 让每个请求携带 token
      // ['X-Token'] 是自定义标题键
      // 请根据实际情况修改
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.status == 200) {
      if (response.data.code === 1) {
        return response.data
      } else {
        Vue.prototype._showMsg(response.data.msg)
      }
    }
  },
  error => {
    if (error.response.status == 401) {
      removeToken()
      router.replace({ name: 'Login' })
    }
    return Promise.reject(error)
  }
)

export default service

import Lawyer from "@/views/lawyer/Lawyer.vue";
const navRouter = [
	{
		path: "/home",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Home",
				component: () => import("@/views/lawyer/Home.vue"),
				meta: { title: "首页", icon: "home" },
			},
		],
	},
	{
		path: "/attendance-records",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "AttendanceRecords",
				component: () => import("@/views/lawyer/AttendanceRecords.vue"),
				meta: { title: "考勤记录", icon: "checking" },
			},
		],
	},
	{
		path: "/contacts",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Contacts",
				component: () => import("@/views/lawyer/Contacts.vue"),
				meta: { title: "通讯录", icon: "contacts" },
			},
		],
	},
	{
		path: "/approval",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Approval",
				component: () => import("@/views/lawyer/Approval/Approval.vue"),
				meta: { title: "我的审批", icon: "approval" },
			},
		],
	},
	{
		path: "/client",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Client",
				component: () => import("@/views/lawyer/Client.vue"),
				meta: { title: "客户管理", icon: "client" },
			},
		],
	},
	{
		path: "/case",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Case",
				component: () => import("@/views/lawyer/Case.vue"),
				meta: { title: "我的案件", icon: "case" },
			},
		],
	},
	{
		path: "/daily",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Daily",
				component: () => import("@/views/lawyer/Daily.vue"),
				meta: { title: "日报审批", icon: "daily" },
			},
		],
	},
	{
		path: "/memo",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Memo",
				component: () => import("@/views/lawyer/Memo.vue"),
				meta: { title: "备忘录", icon: "memo" },
			},
		],
	},
	{
		path: "/system",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "System",
				component: () => import("@/views/lawyer/System.vue"),
				meta: { title: "网盘系统", icon: "system" },
			},
		],
	},
];
export default navRouter;

<template>
  <div class="container" :class="{borderBottom:isBorder}">
    <!-- 搜索框 -->
    <div class="search" v-if="isSearch">
      <span class="txt">{{ searchTitle }}：</span>
      <input class="inp" type="text" v-model="searchValue" :placeholder="placeholder" />
    </div>
    <div class="data" v-if="isDate">
      <span class="txt">时间：</span>
      <el-date-picker v-if="scope" v-model="dateValue" type="daterange" :value-format="valueFormat" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-date-picker v-else v-model="dateValue" type="date" :value-format="valueFormat" placeholder="选择日期"></el-date-picker>
    </div>
    <div class="btn" @click="inquire">查询</div>
  </div>
</template>

<script>
export default {
  name: 'search',
  data() {
    return {
      searchValue: '',
      dateValue: ''
    }
  },
  props: {
    searchTitle: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '姓名/电话'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    isDate: {
      type: Boolean,
      default: false
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
    scope: {
      type: Boolean,
      default: true,
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    }
  },
  methods: {
    inquire() {
      this.$emit('search', {
        keyWord: this.searchValue,
        date: this.dateValue
      })
    }
  }
}
</script>
<style >
.el-range-editor.el-input__inner {
  height: 2.29rem;
  line-height: 2.29rem;
  padding: 0 0.68rem;
}
</style>
<style lang="scss" scoped>
.borderBottom {
  border-bottom: 1px solid #edf2f2;
}
.container {
  display: flex;
  align-items: center;

  .search,
  .data {
    padding: 1.2rem 2rem;
    display: flex;
    align-items: center;
    .txt {
      font-size: 0.83rem;
      font-weight: 400;
      color: #333333;
      line-height: 1rem;
    }
    .inp {
      padding: 0 0.5rem;
      width: 15.68rem;
      height: 2.19rem;
      background: #ffffff;
      border: 1px solid #ced0d0;
      border-radius: 0.31rem;
      font-size: 0.73rem;
      font-weight: 400;
      color: #999999;
      line-height: 1;
    }
    .btn {
      margin-left: 1.94rem;
    }
  }
  .btn {
    height: 2.08rem;
    line-height: 2.08rem;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 0.83rem;
    color: #ffffff;
    padding: 0 1.09rem;
    border-radius: 0.31rem;
    background: linear-gradient(-109deg, #fc9940, #f37902);
    cursor: pointer;
  }
}
</style>

<template>
  <div class="operating-page view-page">
    <div class="state-container">
      <div class="state" ref="state">
        <div class="search-container">
          <!-- <input class="search" placeholder="请输入内容" v-model="searchValue" /> -->
        </div>
        <div class="news" @click="goNews">
          <img class="icon" :src="iconUrl('information')" alt="" />
          <p class="txt">消息</p>
          <!-- <p class="num">9</p> -->
        </div>
        <div class="announcement" @click="goAffiche">
          <img class="icon" :src="iconUrl('announcement')" alt="" />
          <p class="txt">公告</p>
          <!-- <p class="num">99</p> -->
        </div>
        <div class="information" @click="informationHeight = !informationHeight">
          <img class="information-avatar" :src="userAvatar" alt="" />
          <p class="information-name">{{ userName }}</p>
          <img class="information-down" :src="iconUrl('down')" alt="" />
        </div>
        <div class="information-menu" :style="{ height: informationHeight ? '8.55rem' : '0' }">
          <div class="menu_list" @click="changePassword">
            <img class="img" :src="iconUrl('password')" alt="" />
            <p class="txt">修改密码</p>
          </div>
          <div class="menu_list" @click="getPersonageInfo">
            <img class="img" :src="iconUrl('personal-data')" alt="" />
            <p class="txt">个人信息</p>
          </div>
          <div class="menu_list" @click="logout">
            <img class="img" :src="iconUrl('quit')" alt="" />
            <p class="txt">退出</p>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="menu-container" :style="{ height: menuHeight + 'px' }">
        <img class="logo" :src="iconUrl('logo')" alt="logo" />
        <div class="sidebar" :style="{ height: menuHeight - 200 + 'px' }" v-if="sidebarMenu.length > 1">
          <template v-for="(item1, index1) in sidebarMenu">
            <div class="level-1" :class="{ 'level-1-active': item1.active }" :key="'level-1' + index1" @click="toRouter(item1)">
              <img class="icon" v-if="item1.active" :src="iconUrl(`${item1.icon}-active`)" alt="" />
              <img class="icon" v-else :src="iconUrl(item1.icon)" alt="" />
              <p class="txt">{{ item1.title }}</p>
              <i v-if="item1.children" class="item1-icon el-icon-arrow-down" :class="{'item1-active-icon':item1.active}"></i>
            </div>
            <template v-if="item1.children">
              <div class="level-2-container" :style="{'max-height':item1.active?'36rem':'0px'}" :key="'level-2-container' + index1">
                <template v-for="(item2, index2) in item1.children">
                  <div class="level-2" :key="'level-2' + index2" @click="toRouter(item2)">
                    <img class="icon" :src="iconUrl(item2.icon)" alt="" />
                    <p class="txt">{{ item2.title }}</p>
                  </div>
                  <template v-if="item2.children">
                    <div class="level-3-container" :style="{height:item2.active?`${item2.children.length*3}rem`:'0px'}" :key="'level-3-container' + index2">
                      <div class="level-3" :class="{ 'level-3-active': item3.active }" v-for="(item3, index3) in item2.children" :key="'level-3' + index3" @click="toRouter(item3)">
                        <p class="txt">{{ item3.title }}</p>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="content-container">
        <div class="openLink" ref="openLink">
          <div class="link" v-for="(value, key) in tags" :key="key">
            <img class="icon" v-if="key !== 'Home'" :src="iconUrl('close')" alt="" @click="closeTree(value)" />
            <div class="btn" :class="{ 'btn-active': activeTag === key }" @click="toRouter(value)">{{ value.title }}</div>
          </div>
        </div>
        <div class="content" :style="{ height: contentHeight + 'px' }">
          <slot></slot>
        </div>
      </div>
    </div>

    <!--  修改密码 -->
    <c-dialog v-model="showChangePassword" title="修改密码" @confirm="serChangeNewPassword" :mask="true">
      <div class="addMemoPass">
        <el-form :inline="true" :model="form" label-width="150px" size="mini">
          <el-row class="demo-form-inline">
            <el-col :span="24">
              <el-form-item label="原密码：">
                <el-input v-model="form.oldpwd" placeholder="原密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="新密码：">
                <el-input v-model="form.password" placeholder="新密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="再次输入新密码：">
                <el-input v-model="form.password_confirm" placeholder="再次输入新密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </c-dialog>

    <!--  个人信息 -->
    <c-dialog v-model="showPersonalInfo" title="个人信息" :mask="true" :cancel="false" :save="false">
      <div class="addMemo">
        <el-row class="touxiang">
          <el-col :span="8" style="text-align: right"> 头像： </el-col>
          <el-col :span="16">
            <img :src="userInfo.avatar" alt="" />
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8" style="text-align: right"> 姓名： </el-col>
          <el-col :span="16">
            {{ userInfo.username }}
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8" style="text-align: right"> 手机号： </el-col>
          <el-col :span="16">
            {{ userInfo.mobile }}
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8" style="text-align: right"> 部门： </el-col>
          <el-col :span="16">
            {{ userInfo.section }}
          </el-col>
        </el-row>
        <!-- <el-row style="margin-top:20px;">
            <el-col :span="8" style="text-align: right;">
              职位：
            </el-col>
            <el-col :span="16">
              职位：
            </el-col>
          </el-row> -->
      </div>
    </c-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import iconUrl from '@/utils/icon.js'
import CDialog from '@/components/c-dialog'
import * as homeApi from '@/api/home.js'

let _self
export default {
  name: 'Operating',
  components: {
    CDialog
  },
  filters: {
    iconFilter(value) {
      if (_self.levelOneActive === value.name) {
        return iconUrl(`${value.meta.icon}-active`)
      }
      return iconUrl(value.meta.icon)
    }
  },
  data() {
    return {
      contentHeight: '',
      menuHeight: '',
      // 搜索
      searchValue: '',
      showPersonalInfo: false,
      // information
      informationHeight: false,
      // nav
      iconUrl: iconUrl,
      showChangePassword: false, // 修改密码框

      // 修改密码提交的数据
      form: {
        oldpwd: '', //原密码
        password: '', //密码
        password_confirm: '' // 再次输入新密码
      },

      oldSpaces: [],
      newSpaces: []
    }
  },
  computed: {
    ...mapState('sidebar', ['sidebarMenu']),
    ...mapState('tags', ['activeTag', 'tags']),
    ...mapState('user', ['userInfo']),
    ...mapGetters(['userAvatar', 'userName'])
  },
  created() {
    _self = this
    this.iconFile = iconUrl
  },
  mounted() {
    const windowheight = window.innerHeight
    const stateHeight = this.$refs.state['offsetHeight']
    const openLinkHeight = this.$refs.openLink['offsetHeight']
    this.contentHeight = windowheight - stateHeight - openLinkHeight - 20
    this.menuHeight = windowheight - stateHeight - 20
    this.$store.commit('sidebar/changeRoute', [0])
  },
  methods: {
    goNews() {
      this.$router.push({ name: 'News' })
      this.$store.commit('tags/addTag', { key: 'News', value: { title: '消息', name: 'News', active: false, space: [1000] } })
      this.$store.commit('sidebar/changeRoute', [1000])
    },
    goAffiche() {
      this.$router.push({ name: 'Affiche' })
      this.$store.commit('tags/addTag', { key: 'Affiche', value: { title: '公告', name: 'Affiche', active: false, space: [1000] } })
      this.$store.commit('sidebar/changeRoute', [1000])
    },
    toRouter(v) {
      if (v.name !== '') {
        this.$store.commit('tags/addTag', { key: v.name, value: v })
        this.$router.push({ name: v.name })
      }
      this.$store.commit('sidebar/changeRoute', v.space)
    },
    closeTree(v) {
      const bool = v.name == this.activeTag
      this.$store.commit('tags/closeTag', v.name)
      this.$router.push({ name: this.activeTag })
      if (bool) {
        this.getTag(this.sidebarMenu)
      }
    },
    getTag(sidebar) {
      sidebar.forEach(i => {
        if (i.name == this.activeTag) {
          this.$store.commit('sidebar/changeRoute', i.space)
        } else if (i.children) {
          this.getTag(i.children)
        }
      });
    },
    logout() {
      this.informationHeight = false
      this.$store.dispatch('user/logout').then(() => {
        this.$router.replace({ name: 'Login' })
      })
    },
    // 修改密码弹框
    changePassword() {
      this.informationHeight = false
      this.showChangePassword = true
    },
    /**
     * 个人信息弹框
     */
    getPersonageInfo() {
      this.showPersonalInfo = true
      this.informationHeight = false
    },
    /**
     * 修改密码
     */
    serChangeNewPassword() {
      homeApi.setAppSavePwd(this.form)
    }
  }
}
</script>

<style lang="scss" scoped>
div,
p {
  box-sizing: border-box;
}
.operating-page {
  background-color: #ebf0f0;
}
.state-container {
  position: relative;
  width: 100rem;
  height: 4.17rem;
  .state {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    width: 86.3rem;
    height: 4.17rem;
    background: #ffffff;
    border: 0px solid #f4f4f4;
    border-radius: 0rem 0rem 1rem 1rem;
    .search-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .search {
        width: 19.27rem;
        height: 2.34rem;
        background: #ebf0f0;
        border-radius: 1.17rem;
        border: none;
        font-size: 0.83rem;
        padding: 0 2.55rem 0 1rem;
        background-image: url("~@/assets/icon/search.png");
        background-repeat: no-repeat;
        background-size: 1.35rem;
        background-position: 16.88rem 0.52rem;
        ::placeholder {
          color: #666;
        }
      }
    }
    .news,
    .announcement {
      position: relative;
      margin: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 1.15rem;
        height: 1.3rem;
      }
      .txt {
        padding-top: 0.26rem;
        font-size: 0.85rem;
        font-weight: 400;
        color: #000000;
      }
      .num {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        color: #fff;
        border-radius: 7px;
        position: absolute;
        top: -6px;
        left: 12px;
        height: 15px;
        padding: 0 3px;
        background-color: #f62941;
        border: 1px solid #fff;
      }
    }
    .information {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      &-avatar {
        width: 2.14rem;
        height: 2.14rem;
        border-radius: 50%;
      }
      &-name {
        padding: 0 0.5rem;
        font-size: 0.94rem;
        font-weight: 400;
        color: #4b5252;
      }
      &-down {
        width: 0.5rem;
        height: 0.25rem;
      }
    }
    .information-menu {
      z-index: 9999;
      position: absolute;
      right: 1rem;
      top: 4.17rem;
      width: 6.51rem;
      background: #fff;
      overflow: hidden;
      transition: all 0.3s;
      .menu_list {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 2.85rem;
        padding-left: 0.5rem;
        .img {
          width: 1.15rem;
          height: 1.15rem;
        }
        .txt {
          margin-left: 0.2rem;
          font-size: 0.83rem;
          font-weight: 400;
          color: #3a4b4b;
          line-height: 1;
        }
      }
    }
  }
}
.main {
  flex-grow: 1;
  display: flex;
  width: 100rem;
  .menu-container {
    position: relative;
    height: 100%;
    background-color: #fff;
    width: 13.54rem;
    background: #ffffff;
    border: 0px solid #f4f4f4;
    box-shadow: 0rem 1rem 2rem 0rem rgba(26, 173, 161, 0.13);
    border-radius: 0rem 1rem 1rem 0rem;
    .logo {
      position: absolute;
      top: -3.9rem;
      left: 3.36rem;
      width: 6.82rem;
      height: 7.81rem;
    }
    .sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 7rem;
      width: 100%;
      overflow: overlay;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #fff;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5e7ea;
        border-radius: 4px;
      }
    }

    .level-1 {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 10.51rem;
      height: 3.75rem;
      line-height: 3.75rem;
      border-radius: 1.25rem;
      padding-left: 1rem;
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .txt {
        padding-left: 1rem;
        width: 6rem;
        line-height: 1;
        font-size: 0.94rem;
      }
      .item1-icon {
        transition: all 0.3s;
      }
      .item1-active-icon {
        transform: rotate(-180deg);
      }
    }
    .level-1-active {
      color: #fff;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      box-shadow: 0rem 0rem 1rem 0rem rgba(23, 158, 147, 0.1);
    }

    .level-2-container {
      flex-shrink: 0;
      overflow: hidden;
      width: 10.51rem;
      background-color: #e9efef;
      border-radius: 0.83rem;
      transition: all 0.3s;
    }
    .level-2 {
      overflow: hidden;
      cursor: pointer;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 10.51rem;
      height: 3rem;
      padding-left: 2rem;
      .icon {
        width: 1rem;
        height: 1rem;
      }
      .txt {
        padding-left: 1rem;
        width: 6rem;
        line-height: 1;
        font-size: 0.94rem;
      }
    }
    .level-3-container {
      overflow: hidden;
      width: 10.51rem;
      transition: height 0.3s;
    }
    .level-3 {
      cursor: pointer;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 10.51rem;
      height: 3rem;
      padding-left: 4rem;
    }
    .level-3-active {
      color: #17a094;
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem 0 2.29rem;
    width: 86.7rem;
    height: 100%;
    .openLink {
      display: flex;
      align-items: center;
      height: 5rem;
      width: 100%;
      overflow: overlay;
      &::-webkit-scrollbar {
        height: 8px;
        background-color: #ebf0f0;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5e7ea;
        border-radius: 4px;
      }
      .link {
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 2.66rem;
      }
      .icon {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 0.6rem;
        height: 0.6rem;
      }
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.83rem;
        font-weight: 400;
        color: #000000;
        line-height: 1;
        padding: 0.6rem 1rem;
        background: #ffffff;
        border-radius: 0.78rem;
      }
      .btn-active {
        color: #fff;
        background: linear-gradient(215deg, #1db8ab, #179d92);
      }
    }
    .content {
      position: relative;
      width: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f1f1f1;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 4px;
      }
    }
  }
}
.addMemo {
  width: 18rem;
}
.addMemoPass {
  width: 25rem;
}
.touxiang {
  // width: 100%;
  display: flex;
  // grid-template-columns: 1fr 2fr;
  // justify-content: center;
  align-items: center;
  margin-bottom: 2.55rem;
  img {
    width: 5.68rem;
    height: 5.68rem;
    border-radius: 50%;
  }
}

// .demo-form-inline {
//   // display: flex;
// }
</style>

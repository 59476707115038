import Vue from "vue";
import VueRouter from "vue-router";
import navRoute from "./modules/nav.js";
import Login from "@/views/Login/Login.vue";
import Register from "@/views/Login/Register.vue";
import Lawyer from "@/views/lawyer/Lawyer.vue";

Vue.use(VueRouter);

/**
 * 注意：子菜单仅在 route children.length >= 1 时出现
 * hidden: false                 如果设置为 true，项目将不会显示在侧边栏中 ( 默认为 false )
 * need: false                   如果想要在子菜单只有一个时显示，设置 need 为 true ( 默认为 false )，注意：当设置为 true 时设置 meta，并配置 title
 * name: ''                 		 路由组件的名字 ( 必须配置，路由的跳转通过路由的 name )
 * meta : { 										 路由元信息
    roles: ['admin','editor']    控制页面角色 ( 可以设置多个角色, roles 必须为数组 )
    title: ''                    侧边栏和面包屑中显示的名称 ( 如果不配置则使用 name )
    icon: '' 										 侧边栏中的图标显示
  }
 */

export const constantRoutes = [
	{
		path: "/login",
		component: Login,
		hidden: true,
		name: "Login",
	},
	{
		path: "/register",
		name: "Register",
		hidden: true,
		component: Register,
	},
	{
		path: "/news",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "News",
				mate: {
					title: "信息",
				},
				hidden: true,
				component: () => import("@/views/lawyer/News.vue"),
			},
		],
	},
	{
		path: "/Affiche",
		component: Lawyer,
		children: [
			{
				path: "index",
				name: "Affiche",
				hidden: true,
				mate: {
					title: "公告",
				},
				component: () => import("@/views/lawyer/Affiche.vue"),
			},
		],
	},

	...navRoute,
];

export const asyncRoutes = [
	{
		path: "/sector",
		component: Lawyer,
		need: true,
		meta: { title: "部门操作", icon: "sector" },
		children: [
			{
				path: "legal-affairs",
				component: {
					render: h => h("router-view"),
				},
				need: true,
				meta: { title: "法务部", icon: "legalAffairs", roles: "fawu" },
				children: [
					{
						path: "follow",
						name: "Follow",
						component: () => import("@/views/lawyer/Sector/LegalAffairs/Follow.vue"),
						meta: {
							title: "跟进案件",
						},
					},
				],
			},
			{
				path: "hr",
				component: {
					render: h => h("router-view"),
				},
				meta: { title: "人事部", icon: "hr", path: "hr", roles: "yewu" },
				children: [
					{
						path: "check-in",
						name: "CheckIn",
						component: () => import("@/views/lawyer/Sector/Hr/CheckIn.vue"),
						meta: { title: "每日打卡" },
					},
					{
						path: "attendance",
						name: "Attendance",
						component: () => import("@/views/lawyer/Sector/Hr/Attendance.vue"),
						meta: { title: "考勤数据" },
					},
					{
						path: "document",
						name: "Document",
						component: () => import("@/views/lawyer/Sector/Hr/Document.vue"),
						meta: { title: "文件交接" },
					},
					{
						path: "property",
						name: "Property",
						component: () => import("@/views/lawyer/Sector/Hr/Property.vue"),
						meta: { title: "公司财产" },
					},
				],
			},
			// {
			//   path: 'reception',
			//   component: {
			//     render: h => h('router-view')
			//   },
			//   meta: { title: '前台', icon: 'reception', roles: ['前台'] },
			//   children: [
			//     {
			//       path: 'upload',
			//       name: 'Upload',
			//       component: () => import('@/views/lawyer/Sector/Reception/Upload.vue'),
			//       meta: { title: '上传文件' }
			//     },
			//     {
			//       path: 'case-list',
			//       name: 'CaseList',
			//       component: () => import('@/views/lawyer/Sector/Reception/CaseList.vue'),
			//       meta: { title: '案件列表' }
			//     }
			//   ]
			// },
			// {
			//   path: 'leader',
			//   component: {
			//     render: h => h('router-view')
			//   },
			//   need: true,
			//   meta: { title: '总监', icon: 'leader', roles: ['总监'] },
			//   children: [
			//     {
			//       path: 'case-inquiry',
			//       name: 'CaseInquiry',
			//       component: () => import('@/views/lawyer/Sector/Leader/CaseInquiry.vue'),
			//       meta: { title: '案件查询' }
			//     }
			//   ]
			// }
		],
	},
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

const createRouter = () =>
	new VueRouter({
		mode: "history",
		base: process.env.BASE_URL,
		routes: constantRoutes,
	});

const router = createRouter();

export function resetRouter() {
	const newRouter = createRouter();
	/**
	 * 当我们要重置路由时只需要替换掉路由实例的 matcher 属性值即可
	 * 因为当我们跳转路由时都是在 matcher 查找路由的匹配项，然后实现路由的跳转，因此重新赋值则可以重置路由
	 */
	router.matcher = newRouter.matcher;
}

export default router;

<template>
  <div class="c-table" :class="{'table-padding':isPadding}">
    <el-table class="el-table" :data="data" :header-cell-style="tableHeaderColor">
      <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    isPadding: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableHeaderColor: {
        backgroundColor: '#F3F3F5',
        color: '#333333',
        textAlign: 'center',
        fontSize: '0.83rem'
      },
    }
  }
}
</script>

<style lang="scss">
.table-padding {
  padding: 0 2rem;
}
.el-table__body tr:hover > td.el-table__cell {
  background-color: #eaf0f0;
}
.el-table {
  width: 100%;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  font-size: 0.83rem;
  color: #333333;
  .table-operate {
    display: flex;
    align-items: center;
    justify-content: center;
    .operate-list {
      cursor: pointer;
      padding: 0 10px;
    }
  }
}
</style>
<template>
  <div class="navigation" :class="{ borderBottom: isBorder }">
    <div class="navigation-title">{{ title }}：</div>
    <div class="navigation-list">
      <span v-for="item in lists" :key="item.id" :class="{ 'span-bg': item.isChecked }" @click="checkedClassify(item)">{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import clone from '@/utils/clone.js'
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lists: []
    }
  },
  created() {
    this.lists = clone(this.list)
  },
  methods: {
    checkedClassify(item) {
      this.lists.forEach(item => {
        item.isChecked = false;
      });
      item.isChecked = true;
      this.$emit("change", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.borderBottom {
  border-bottom: 1px solid #f1f4f4;
}
.navigation {
  padding: 1.2rem 1.98rem;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 0.83rem;
  font-weight: 400;
  .navigation-title {
    flex-shrink: 0;
  }
  &-list {
    margin-left: 1rem;
    span {
      padding: 0 1.4rem;
      height: 1.82rem;
      text-align: center;
      line-height: 1.82rem;
      display: inline-block;
      box-sizing: border-box;
      border-radius: 0.42rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    span:hover {
      color: #ffffff;
      background: linear-gradient(-215deg, #1db8ab, #179d92);
    }

    .span-bg {
      color: #ffffff;
      background: linear-gradient(-215deg, #1db8ab, #179d92);
    }
  }
}
</style>
